import React, { Fragment } from 'react';
import { submitForm, api, saiki, pesan, convertToBase64, host } from '../Modul';
import Pagination from '../component/Pagination';
import RendTable from '../component/RendTable';
import RendForms from '../component/RendForms';

class MenuArtikel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Detail: {},
            JmlData: 0,
            ID: 0,
            Order: "Judul",
            By: "ASC",
            Page: 1,
            q: "",
            KategoriID: "",
            saveCls: "fa fa-save",
            thead: [],
            tbody: []
        };
    }

    async componentDidMount() {
        this.handleMain();
        let ColorTheme = localStorage.getItem("ColorTheme") || "light";
        if (ColorTheme == "dark") {
            let cls = document.getElementsByClassName("table");
            for (let i = 0; i < cls.length; i++) {
                cls[i].classList.add("table-dark")
            }
        }
    }

    async handleMain() {
        let sql = await api("/artikel", { act: "data research", order: this.state.Order, by: this.state.By, page: this.state.Page, q: this.state.q, KategoriID: this.state.KategoriID });
        if (sql.status == "sukses") {
            let thead = [
                { text: "", field: "", sort: "", type: "opsi", width: "150px" },
                { text: "Judul", field: "Judul", sort: "Judul" },
                { text: "Tanggal", field: "Tanggal", sort: "Tanggal", type: "date" },
                { text: "Tanggal Buat", field: "TimeCreated", sort: "TimeCreated", type: "date" },
                { text: "Status", field: "Status", sort: "Status" }
            ];
            this.setState({ tbody: sql.data, thead: thead, JmlData: sql.j });
        }
    }

    handleSort(Field) {
        if (this.state.By == "DESC") {
            this.setState({ Order: Field, By: "ASC" });
        } else {
            this.setState({ Order: Field, By: "DESC" });
        }
        this.handleMain();
    }

    handlePaggination(e) {
        if (e.target.className == "page-link") {
            let Page = e.target.innerText;
            this.setState({ Page: Page });
            setTimeout(() => {
                this.handleMain();
            }, 200);
        }
    }

    async handleDetail(Detail) {
        let sql = await api("/artikel", { act: "detail research", ID: Detail.ID });
        if (sql.status == "sukses") {
            this.setState({ Detail: sql.data, ID: Detail.ID });
            let modal = new window.bootstrap.Modal(document.getElementById('modalArtikel'), {});
            modal.show("#modalArtikel");
        } else {
            pesan("Gagal", sql.pesan, "warning");
        }
    }

    handleDelete(data) {
        this.setState({ ID: data.ID });
        let modal = new window.bootstrap.Modal(document.getElementById('modalDelete'), {});
        modal.show("#modalDelete");
    }

    convertYoutubeLinkToEmbed(link) {
        const regex = /^https?:\/\/(?:www\.)?(?:youtube\.com|youtu\.be)\/(?:watch\?v=)?(.+)$/;
        const match = link.match(regex);
        if (match) {
            const videoId = match[1];
            return `https://www.youtube.com/embed/${videoId}`;
        }
    }

    render() {
        return (
            <Fragment>
                <div className="main-header">
                    <button className='btn btn-default' data-bs-toggle="modal" data-bs-target="#modalArtikel" onClick={() => {
                        let Detail = {
                            ID: "",
                            Judul: "",
                            Tanggal: saiki(),
                            KategoriID: "",
                            Artikel: "",
                            Status: "Draft",
                            Banner: ""
                        }
                        this.setState({ ID: "", Detail: Detail });
                    }}>Tambah Pojok Research</button>
                </div>
                <div className="main-body">
                    <div className="div-content mt-1">
                        <div className="row">
                            <div className="col-md-9 main-title">Master Pojok Research</div>
                            <div className="col-md-3 d-flex gap-1">
                                <div className="input-group gap-2">
                                    <input type="search" className="form-control form-control-sm" value={this.state.q} onChange={(e) => this.setState({ q: e.target.value })} placeholder='Cari Konten' />
                                    <button className="btn btn-default" onClick={() => this.handleMain()} ><i className="fas fa-search"></i></button>
                                </div>
                            </div>
                        </div>
                        <RendTable thead={this.state.thead} tbody={this.state.tbody} opt={{
                            sort: (e) => this.handleSort(e),
                            opsi: [
                                { icon: "fas fa-edit", text: "Edit", opsi: (e) => this.handleDetail(e) },
                                { icon: "fas fa-trash", text: "Hapus", opsi: (e) => this.handleDelete(e) }
                            ]
                        }} />
                        <Pagination Jml={this.state.JmlData} Page={this.state.Page} click={(e) => this.handlePaggination(e)} />
                    </div>
                </div>

                {/* Untuk array Banner Atas */}
                <div className="modal fade" id="modalArtikel" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-xl">
                        <form onSubmit={(e) => submitForm(e, { crud: "artikel", fn: () => this.handleMain(), debug: true })} className='needs-validation' noValidate>
                            <input type='hidden' name="act" value={this.state.ID == "" ? "tambah research" : "edit research"} />
                            <input type='hidden' name="ID" value={this.state.ID} />
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5">{this.state.ID == "" ? "Tambah" : "Edit"} Pojok Research</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <RendForms type="Artikel" Detail={this.state.Detail} />
                                    <p></p>
                                    <div className="form-group">
                                        <label>Jenis Referensi / Sumber</label>
                                        <select className="form-select" name='FileType' id="edtFileType" value={this.state.Detail.FileType} onChange={(e) => {
                                            let data = this.state.Detail;
                                            data.FileType = e.target.value;
                                            this.setState({ Detail: data });
                                        }} >
                                            <option value="">Silahkan tentukan Jenis Sumber / Referensi</option>
                                            <option value="Pdf">Pdf</option>
                                            <option value="Youtube">Youtube</option>
                                            <option value="Link">Link</option>
                                            <option value="Word">Ms. Word</option>
                                        </select>
                                        <div className="invalid-feedback">Silahkan Tentukan jenis sumber</div>
                                    </div>
                                    {
                                        this.state.Detail.FileType == "Pdf" ?
                                            <div className='form-group'>
                                                <label>File Pdf</label>
                                                <input type='file' name="Files" className='form-control' accept='.pdf' required={this.state.ID == "" ? true : false} />
                                                <div className='invalid-feedback'>Silahkan pilih file</div>
                                            </div> : this.state.Detail.FileType == "Link" ?
                                                <div className='form-group'>
                                                    <label>Link</label>
                                                    <input type='link' name="Files" className='form-control' value={this.state.Detail.Files} onChange={(e) => {
                                                        let data = this.state.Detail;
                                                        data.Files = e.target.value;
                                                        this.setState({ Detail: data });
                                                    }} />
                                                    <div className='invalid-feedback'>Silahkan masukan link</div>
                                                </div> : this.state.Detail.FileType == "Youtube" ? <div className='form-group'>
                                                    <label>Link Youtube</label>
                                                    <input type='link' name="Files" className='form-control' value={this.state.Detail.Files} onChange={(e) => {
                                                        let data = this.state.Detail;
                                                        data.Files = this.convertYoutubeLinkToEmbed(e.target.value);
                                                        this.setState({ Detail: data });
                                                    }} />
                                                    <div className='invalid-feedback'>Silahkan masukan link</div>
                                                </div> : this.state.Detail.FileType == "Word" ?
                                                    <div className='form-group'>
                                                        <label>File Word</label>
                                                        <input type='file' name="Files" className='form-control' accept='.docx, application/vnd.openxmlformats-officedocument.wordprocessingml.document' required={this.state.ID == "" ? true : false} />
                                                        <div className='invalid-feedback'>Silahkan pilih file</div>
                                                    </div> : ""
                                    }
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-danger" data-bs-dismiss="modal" id='btnTutupModalArrayProduk'>Batal</button>
                                    <button type="submit" className="btn btn-default"><i className={this.state.saveCls}></i> Simpan</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                {/* Modal Delete */}
                <div className="modal fade" id="modalDelete" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <form onSubmit={(e) => submitForm(e, { crud: "artikel", fn: () => this.handleMain() })} className='needs-validation' noValidate>
                            <input type='hidden' name="act" value="hapus research" />
                            <input type='hidden' name="ID" value={this.state.ID} />
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5">Hapus Pojok Research</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <h5>Apakah anda yakin akan menghapus artikel ini!!</h5>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" id='btnTutupModalHapus'>Batal</button>
                                    <button type="submit" className="btn btn-danger"><i className="fas fa-trash"></i> Hapus</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </Fragment>
        )
    }

}

export default MenuArtikel;